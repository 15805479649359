@tailwind base;
@tailwind components;
@tailwind utilities;

/* put styles that are sitewide here.
they should be few and far between. */
a:link,
a:visited {
  text-decoration: underline;
}
a:hover,
a:active {
  text-decoration: none;
}

button {
  line-height: initial;
}

svg {
  display: inline;
  vertical-align: initial;
}