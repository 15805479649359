.globalPreFooter > div {
  margin-top: 0;
}
.globalPreFooter :global(.cta-container) {
  margin: 0 auto !important;
}
.globalPreFooter :global(.cta-container .cta-position-fullwidth) {
  padding-bottom: 0 !important;
}
.globalPreFooter :global(.cta-container .cta-position-fullwidth .cta) {
  border-radius: 0px;
  margin: 0px auto;
  min-height: 378px;
}
.globalPreFooter
  :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
  width: 95%;
}
.globalPreFooter
  :global(
    .cta-container .cta-position-fullwidth .cta .cta-inside .cta-heading
  ) {
  font-size: 26px;
  line-height: 31px;
}
.globalPreFooter
  :global(
    .cta-container .cta-position-fullwidth .cta .cta-inside .cta-buttons
  ) {
  margin-top: 35px;
}
@media screen and (min-width: 425px) {
  .globalPreFooter
    :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
    padding: 120px 0px 30px;
  }
}
@media screen and (min-width: 769px) {
  .globalPreFooter
    :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
    max-width: 96%;
  }
  .globalPreFooter :global(.cta-container .cta-position-fullwidth .cta) {
    min-height: 418px;
  }
  .globalPreFooter
    :global(
      .cta-container .cta-position-fullwidth .cta .cta-inside .cta-heading
    ) {
    font-size: 32px;
    line-height: 38px;
  }
  .globalPreFooter
    :global(
      .cta-container .cta-position-fullwidth .cta .cta-inside .cta-buttons
    ) {
    margin-top: 48px;
  }
}
@media only screen and (min-width: 1680px) {
  .globalPreFooter
    :global(.cta-container .cta-position-fullwidth .cta .cta-inside) {
    padding: 160px 0px 0px;
  }
}
